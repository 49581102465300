import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/Layout'

const NoPage = () => (
  <Layout headerText='NATURSKYLT'>
    <oma-grid-row>
      <section className='section'>
        <p>Det finns ingen sida på den här adressen.</p>
        <p>
          <Link to='/'>Tillbaka till startsidan</Link>
        </p>
      </section>
    </oma-grid-row>
  </Layout>
)

export default NoPage
